import React, { Component } from "react"
import Layout from "../components/layout"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Select from 'react-select'
import {
  AUTH_TOKEN_KEY,
  NL,
  FR,
  firstLetterCapitalize,
  KEY_EMAIL,
  KEY_PASSWORD_TEMP,
  PARAM_CARD_NMBRE,
  URL_GET_NACEBELCODES,
  URL_SEND_MAIL_USER,
  URL_GETONE_ADVANTAGE,
  URL_GETONE_BCEDATA_BYNCRDNUMBER,
  URL_GETONE_CONTACT_BYEMAIL,
  URL_LOGIN,
  URL_SIGNUP_USER,
  BCE_JUR_FORM_NAME_NV,
  BCE_JUR_FORM_NAME_BVBA,
  BCE_JUR_FORM_NAME_CV,
  BCE_JUR_FORM_NAME_Eenmanszaak,
  BCE_JUR_FORM_NAME_VZW,
  BCE_JUR_FORM_NAME_Overige_rechtsvormen,
  PARAM_CARD_CORECT_NOT_FOUND,
  PARAM_NOT_YET,
  translate,
  getUserLanguage,
  ALERT_MSG_ERROR,
  decreaseMonthsFromCurrentDate,
  convertddmmyyyyTommddyyyy,
  BCE_JUR_FORM_NAME_Commanditaire_Vennootschap,
  BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid, BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma
} from "../utils"
import axios from "axios"
import { v } from "react-select/dist/index-4bd03571.esm"
import ModalAlert from "../components/modalAlert"
import dataCode from "../utils/code-postaux-belge.json"; 
import { isMobile } from "react-device-detect";
class UserCheckInfoTwo extends Component {
    constructor(props) {
      super(props);
    }
    
    state = { 
      tempDescriptionActivity: '',
      descritpionActivityMatches:[],
      nacebelCodes: [],
      email: '',
      ncrd_num: '',
      haveNcrdNumNotOnBCE: false,
      nom_de_commerce: '',
      numero_d_enterprise: '',
      code_d_activite: '',
      establishmentDate: '',
      establishmentDateValue: null,
      rue: '',
      numero_de_maison_ajout: '',
      code_postal: '',
      lieu: '',
      nom_1: '',
      sexe_1: '',
      numero_de_telephone: '',
      langue_operationnelle: '',
      prenom_1: '',
      optIn1: false,
      optIn2: false,
      options: [],
      selectedValue: '',
      description_forme_juridique: '',
      valueFormJuridique: '',
      notFound: true,
      companyExist: true,
      userLanguage: NL,
      showAlert: false,
      alertTitle: '',
      alertMessage: '',
      placeholderDateEtablishment: '',
      placeholderCompanyName: '',
      placeholderStreet: '',
      placeholderNumber: '',
      placeholderPostCode: '',
      placeholderCompanyNumber: '',
      placeholderNacebel: '',
      placeholderCity: '',
      placeholderFormJuredique: '',
      placeHolderDateCompanyExist: '',
      placeHolderDateCompanyUnexist: '',
      maanden:'',
      meerdan:'',
      jaaren:'',
      validBtColor: true,
     
    }

    
    componentDidMount = async () => {
      await this.setState({
        placeholderDateEtablishment: translate('date d\'établissement'),
        placeholderCompanyName: translate('bedrijfsnaam')+"*",
        placeholderStreet: translate("straat"),
        placeholderNumber: translate("nummer"),
        placeholderPostCode: translate("postcode"),
        placeholderCompanyNumber: translate('bedrijfnummer'),
        placeholderNacebel: translate("sleutelwoord van uw branche"),
        placeholderCity: translate("stad"),
        placeholderFormJuredique: translate("forme juridique")+"*",
        placeHolderDateCompanyExist: translate("hoe lang bestaat het bedrijf al")+"?*",
        placeHolderDateCompanyUnexist: translate("wanneer ben je van plan om je bedrijf op te richten")+"?*",
        maanden:translate("maanden",false,false),
        meerdan:translate('meer dan'),
        jaaren:translate('jaaren',false,false),
      })


      

      const userLanguage = getUserLanguage();
      if (userLanguage) await this.setState({userLanguage})

      const email = localStorage.getItem(KEY_EMAIL);
      if (email) {
        this.setState({email})
      }else {
        //Redirect to first step signup form
      }
      await this.getNacebelCode();
      await this.getDataFromBce();
      await this.getDataFromContact();

      const query = new URLSearchParams(this.props.location.search);
      if (query.get(PARAM_CARD_NMBRE) !== null) {
        if (query.get(PARAM_CARD_CORECT_NOT_FOUND) !== null) {
          if (query.get(PARAM_CARD_CORECT_NOT_FOUND) === 'true') {
            this.setState({haveNcrdNumNotOnBCE: true})
          }
        }
      }
      if (query.get(PARAM_NOT_YET) !== null) {
        if (query.get(PARAM_NOT_YET) === 'true') this.setState({companyExist:false})
      }

    }
  
    getDataFromBce = async () => {
      if (this.props.location) {
        const query = new URLSearchParams(this.props.location.search);
        if ((query.get(PARAM_CARD_NMBRE) !== null) && (query.get(PARAM_CARD_NMBRE) !== '') && (query.get(PARAM_CARD_NMBRE) !== 'false')) {
          await this.setState({ncrd_num: query.get(PARAM_CARD_NMBRE)});
          const data = {
            ncrd_num: this.state.ncrd_num,
          };
          axios.post(URL_GETONE_BCEDATA_BYNCRDNUMBER,data)
            .then(res => {
              if (res.data) {
                const {
                  nom_de_commerce,
                  description_forme_juridique,
                  numero_d_enterprise,
                  code_d_activite,
                  establishmentDate,
                  rue,
                  numero_de_maison_ajout,
                  code_postal,
                  lieu,
                } = res.data;
                this.setState({notFound: false})

                /*
                tion>NV</option>
                          <option>BV</option>
                          <option>CV</option>
                          <option>Eenmanszaak</option>
                          <option>VZW</option>
                          <option>Overige vennootschapsvormen
                 */

                const {userLanguage} = this.state;
                console.log('nouga form jur',description_forme_juridique,userLanguage)
                if (userLanguage === NL) {
                  switch (description_forme_juridique) {
                    case BCE_JUR_FORM_NAME_NV.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_NV.abreviationNL})
                      break;
                    case BCE_JUR_FORM_NAME_BVBA.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_BVBA.abreviationNL})
                      break;
                    case BCE_JUR_FORM_NAME_CV.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_CV.abreviationNL})
                      break;
                    case BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Eenmanszaak.abreviationNL})
                      break;
                    case BCE_JUR_FORM_NAME_VZW.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_VZW.abreviationNL})
                      break;
                    /**/
                    case BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.abreviationNL})
                      break;
                    case BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.abreviationNL})
                      break;
                    case BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.abreviationNL})
                      break;
                    /**/
                    case BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Overige_rechtsvormen.abreviationNL})
                      break;
                    default:
                      console.log(`forme juridique ${description_forme_juridique} not found`);
                  }
                }else if (userLanguage === FR) {
                  switch (description_forme_juridique) {
                    case BCE_JUR_FORM_NAME_NV.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_NV.fromBceName})
                      break;
                    case BCE_JUR_FORM_NAME_BVBA.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_BVBA.fromBceName})
                      break;
                    case BCE_JUR_FORM_NAME_CV.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_CV.fromBceName})
                      break;
                    case BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName:
                      console.log('nouga ici')
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName})
                      break;
                    case BCE_JUR_FORM_NAME_VZW.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_VZW.fromBceName})
                      break;
                    /**/
                    case BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName})
                      break;
                    case BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName})
                      break;
                    case BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName})
                      break;
                    /**/
                    case BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName:
                      this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName})
                      break;
                    default:
                      console.log(`forme juridique ${description_forme_juridique} not found`);
                  }
                }

                if (userLanguage === FR) {
                  for (const item of this.state.nacebelCodes) {
                    if (item.code_d_activite === code_d_activite) {
                      this.setState({selectedValue: {
                          label : item.description_d_activite,
                          value: item.id
                        }})
                    }
                  }

                }else {
                  for (const item of this.state.nacebelCodes) {
                    if (item.code_d_activite === code_d_activite) {
                      this.setState({selectedValue: {
                          label : item.description_d_activite_NL,
                          value: item.id
                        }})
                    }
                  }
                }
                /*this.setState({selectedValue: {
                    label: 'Vervaardiging van krantenpapier en van ander druk- of schrijfpapier',
                    value: 5
                  }})*/


                this.setState({
                  haveNcrdNumNotOnBCE: false,
                  nom_de_commerce,
                  description_forme_juridique,
                  numero_d_enterprise : `BE${numero_d_enterprise}`,
                  establishmentDate,
                  establishmentDateValue:establishmentDate,
                  rue,
                  numero_de_maison_ajout,
                  code_postal,
                  lieu: firstLetterCapitalize(lieu),
                  numero_de_telephone: '',
                  code_d_activite, 
                })
              }
            })
            .catch(err => {
              if (err.response) {
                console.log(err.response.data);
              }
              console.log(err)
            });
        }
      }
    }
  
    getNacebelCode = async () => {
      await axios.get(URL_GET_NACEBELCODES)
        .then(res => {
          if (res.data) {
            this.setState({nacebelCodes: res.data})
          }
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data);
          }
          console.log(err)
        });
      const {userLanguage} = this.state;
      if (userLanguage === FR) {
        const options = this.state.nacebelCodes.map(item => ({
          label: item.description_d_activite,
          value: item.id
        }));
        this.setState({ options : options });
      }else {
        const options = this.state.nacebelCodes.map(item => ({
          label: item.description_d_activite_NL,
          value: item.id
        }));
        this.setState({ options : options });
      }
    }

    getDataFromContact = async () => {
      const {email} = this.state;
      axios.post(URL_GETONE_CONTACT_BYEMAIL,{email})
        .then(async res => {
          if (res.data) {
            const {sexe_1,nom_1,prenom_1,numero_de_telephone,langue_operationnelle} = res.data;
            this.setState({
              sexe_1,
              nom_1,
              prenom_1,
              numero_de_telephone,
              langue_operationnelle
            })
          }
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data);
            console.log(err.response.data.message)
          }
          console.log(err)
        })
    }


      checkCP(CP){
        let exist=0;
        console.log(CP)
        dataCode.map((data, key) => { 
        if(data.column_1==CP){
          let exist=1;
        }

        if(exist==0) console.log("error code");
        })
      }
  
    signupUser = async (e) => {
      e.preventDefault();
      const {email,ncrd_num,haveNcrdNumNotOnBCE,nom_de_commerce,description_forme_juridique,
      numero_d_enterprise,establishmentDate,establishmentDateValue,rue,numero_de_maison_ajout,code_postal,
      lieu,prenom_1,nom_1,sexe_1,numero_de_telephone,langue_operationnelle,optIn1,optIn2,code_d_activite} = this.state

      let date = '01/01/2021';
      if (establishmentDate !== '') {
        date = convertddmmyyyyTommddyyyy(establishmentDate);
      }else if (establishmentDateValue) {
        const calculateDate = this.getDateFromSelectValue(establishmentDateValue);
        date = convertddmmyyyyTommddyyyy(calculateDate)
      }

      const data = {
        email,ncrd_num,haveNcrdNumNotOnBCE,nom_de_commerce,description_forme_juridique,
        numero_d_enterprise,
        establishmentDate: date,rue,numero_de_maison_ajout,code_postal,
        lieu,prenom_1,nom_1,sexe_1,numero_de_telephone,langue_operationnelle,optIn1,optIn2,code_d_activite
      }
console.table(data);
      axios.post(URL_SIGNUP_USER,data)
        .then(res => {
          if (res.data) {
            if (res.data.account) {
              const passwordTemp = res.data.account.passwordGeneratedTemp;

              let fr = new FormData();
              fr.append("email",res.data.account.email);
              fr.append("lang",this.state.langue_operationnelle);
              if(isMobile){    
              fr.append("device","mobile");
              } else{
                fr.append("device","desktop"); 
              }
              axios.post(URL_SEND_MAIL_USER,fr).then(f=>{
                    
                sessionStorage.setItem(KEY_PASSWORD_TEMP,passwordTemp);
                const signInData = {
                  email: this.state.email,
                  password: passwordTemp
                }
                axios.post(URL_LOGIN,signInData)
                  .then(res => {
                    localStorage.setItem(AUTH_TOKEN_KEY,res.data)
                    window.location.href = '/feed';
                  })
                  .catch(err => {
                    if (err.response && err.response.data) {
                      console.log(err.response.data);
                      this.setState({
                        showAlert: true,
                        alertTitle: ALERT_MSG_ERROR,
                        alertMessage: err.response.data.message,
                      })
                    }
                    console.log(err)
                  })
               }).catch(er=>{console.log("error");}) 
            }
          }
        })
        .catch(err => {
          if (err.response && err.response.data) {
            console.log(err.response.data);
            this.setState({
              showAlert: true,
              alertTitle: ALERT_MSG_ERROR,
              alertMessage: err.response.data.message,
            })
          }
          console.log(err)
        });
  }

  onselectChange(value) {
    this.setState({selectedValue : value });
    let code_d_activite = ''
    this.state.nacebelCodes.forEach(item => {
      //console.log('value',typeof value)
      //console.log('item',typeof item.id)
      if (value.value === item.id) code_d_activite = item.code_d_activite;
    })
    this.setState({code_d_activite})
  }

    
 



  renderDateField = () => {
      if (this.state.establishmentDate !== '') {
        return (
          <Form.Control
            type="text" 
             placeholder={this.state.placeholderDateEtablishment}
            value={this.state.establishmentDate}
            disabled={true}
          />
        )
      }else {
        return (
          <div className="pure-css-select-style theme-default"><Form.Control  onChange={(e)=> { this.setState({establishmentDateValue: e.target.value}) } }  as="select" >
            <option value="" disabled selected>{(this.state.companyExist)?this.state.placeHolderDateCompanyExist:this.state.placeHolderDateCompanyUnexist}</option>
            <option value={0} >0 - 3 {this.state.maanden}</option>
            <option value={1}>4 - 6 {this.state.maanden}</option>
            <option value={2}>7 - 12 {this.state.maanden}</option>
            <option value={3}>1 - 2 {this.state.jaaren}</option>
            <option value={4}>2 - 3 {this.state.jaaren}</option>
            <option value={5}>{this.state.meerdan} 3 {this.state.jaaren}</option>
          </Form.Control></div> 
        )
      }
  }

  getDateFromSelectValue = (selectValue) => {
      let result = '01/01/2021';
      const {companyExist} = this.state
      switch (selectValue) {
        case '0':
          result = decreaseMonthsFromCurrentDate(3*((companyExist)?1:-1))
          break;
        case '1':
          result = decreaseMonthsFromCurrentDate(6*((companyExist)?1:-1))
          break;
        case '2':
          result = decreaseMonthsFromCurrentDate(12*((companyExist)?1:-1))
          break;
        case '3':
          result = decreaseMonthsFromCurrentDate(24*((companyExist)?1:-1))
          break;
        case '4':
          result = decreaseMonthsFromCurrentDate(36*((companyExist)?1:-1))
          break;
        case '5':
          result = decreaseMonthsFromCurrentDate(48*((companyExist)?1:-1))
          break;
        default:
          console.log('error on switch case getDateFromSelectValue');
      }
      return result
  }

  onChangeJuridichSelect = (value) => {
      const {userLanguage} = this.state 
    if (userLanguage === FR) {
      switch (value) {
        case BCE_JUR_FORM_NAME_NV.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_NV.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_NV.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_BVBA.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_BVBA.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_BVBA.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_CV.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_CV.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_CV.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_VZW.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_VZW.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_VZW.fromBceName
          })
          break;
        /**/
        case BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName
          })
          break;
        /**/
        case BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName
          })
          break;
        default:
          console.log(`forme juridique ${value} not found`);
      }
    }else {

      switch (value) {
        case BCE_JUR_FORM_NAME_NV.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_NV.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_NV.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_BVBA.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_BVBA.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_BVBA.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_CV.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_CV.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_CV.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_Eenmanszaak.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Eenmanszaak.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_VZW.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_VZW.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_VZW.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_Overige_rechtsvormen.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Overige_rechtsvormen.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName
          })
          break;
        default:
          console.log(`forme juridique ${value} not found`);
      }
    }

  }

  render() {
     const {valueFormJuridique,ncrd_num,notFound,code_d_activite,companyExist,userLanguage,showAlert,alertMessage,alertTitle,
       placeholderDateEtablishment,
       placeholderCompanyName,
       placeholderStreet,
       placeholderNumber,
       placeholderPostCode,
       placeholderCompanyNumber,
       placeholderNacebel,
       placeholderCity,
       placeholderFormJuredique,establishmentDateValue,establishmentDate} = this.state;

     console.log('nouga form juridique state',valueFormJuridique,establishmentDateValue,establishmentDate)

 
    return (
    <Layout withBg>
      <div className="formWrapper userCheckInfo f13">
        <Container>
          <Row className="justify-content-center">
            <Col lg="7">
              {/*<button onClick={() => console.log('state:',this.state)}>STATE</button>*/}
              <div className="text-left">
                <h1 className="h1-signupin-yellow">{translate("u bent er bijna")+"!"}</h1>
                {!notFound ?
                <p>{translate('we vonden dit bedrijf geassocieerd met dit kaartnummer')}.<br />{translate('gelieve te valideren of dit het juiste bedrijf is of indien nodig correcties aan te brengen')}.</p>
                :
                <p>{translate('Afin de pouvoir vous faire parvenir du contenu qui vous intéresse, nous devons forcément en savoir plus sur votre activité')}. </p>
                }
                <Form onSubmit={(e) => this.signupUser(e)} className="f12">
                  <Row>
                    {(companyExist)?
                      <Col lg="6">
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder={placeholderCompanyName}
                            value={this.state.nom_de_commerce}
                            onChange={e => this.setState({nom_de_commerce: e.target.value})}
                          />
                        </Form.Group>
                      </Col>:null
                    }
                    {(companyExist)?
                    <Col lg="3">
                    <Form.Group> <div className="pure-css-select-style theme-default">
                      {(userLanguage === FR)?
                        (valueFormJuridique!=='')?
                         <Form.Control as="select"  onChange={(e)=> { this.onChangeJuridichSelect(e.target.value)  ; }} >
                           <option  value="" disabled="disabled">{placeholderFormJuredique}</option>
                            <option selected >{valueFormJuridique}</option>
                            {(valueFormJuridique!== BCE_JUR_FORM_NAME_NV.fromBceName)?<option>{BCE_JUR_FORM_NAME_NV.fromBceName}</option>:null}
                            {(valueFormJuridique!== BCE_JUR_FORM_NAME_BVBA.fromBceName)?<option>{BCE_JUR_FORM_NAME_BVBA.fromBceName}</option>:null}
                            {(valueFormJuridique!== BCE_JUR_FORM_NAME_CV.fromBceName)?<option>{BCE_JUR_FORM_NAME_CV.fromBceName}</option>:null}
                            {(valueFormJuridique!== BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName)?<option>{BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName}</option>:null}
                            {(valueFormJuridique!== BCE_JUR_FORM_NAME_VZW.fromBceName)?<option>{BCE_JUR_FORM_NAME_VZW.fromBceName}</option>:null}
                           {/**/}
                           {(valueFormJuridique!== BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName)?<option>{BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName}</option>:null}
                           {(valueFormJuridique!== BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName)?<option>{BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName}</option>:null}
                           {(valueFormJuridique!== BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName)?<option>{BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName}</option>:null}
                           {/**/}
                            {(valueFormJuridique!== BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName)?<option>{BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName}</option>:null}
                          </Form.Control>:
                          <Form.Control as="select" onChange={(e) => this.setState({valueFormJuridique: e.target.value})}>
                            <option  selected value=""  disabled="disabled">{placeholderFormJuredique}</option>
                            <option>{BCE_JUR_FORM_NAME_NV.fromBceName}</option>
                            <option>{BCE_JUR_FORM_NAME_BVBA.fromBceName}</option>
                            <option>{BCE_JUR_FORM_NAME_CV.fromBceName}</option>
                            <option>{BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName}</option>
                            <option>{BCE_JUR_FORM_NAME_VZW.fromBceName}</option>
                            {/**/}
                            <option>{BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName}</option>
                            <option>{BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName}</option>
                            <option>{BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName}</option>
                            {/**/}
                            <option>{BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName}</option>
                          </Form.Control>

                        :

                        (valueFormJuridique!=='')?
                        <Form.Control as="select" onChange={(e) => this.onChangeJuridichSelect(e.target.value)}>
                          <option  value=""  disabled="disabled">{placeholderFormJuredique}</option>
                          <option selected>{valueFormJuridique}</option>
                          {(valueFormJuridique!== BCE_JUR_FORM_NAME_NV.abreviationNL)?<option>{BCE_JUR_FORM_NAME_NV.abreviationNL}</option>:null}
                          {(valueFormJuridique!== BCE_JUR_FORM_NAME_BVBA.abreviationNL)?<option>{BCE_JUR_FORM_NAME_BVBA.abreviationNL}</option>:null}
                          {(valueFormJuridique!== BCE_JUR_FORM_NAME_CV.abreviationNL)?<option>{BCE_JUR_FORM_NAME_CV.abreviationNL}</option>:null}
                          {(valueFormJuridique!== BCE_JUR_FORM_NAME_Eenmanszaak.abreviationNL)?<option>{BCE_JUR_FORM_NAME_Eenmanszaak.abreviationNL}</option>:null}
                          {(valueFormJuridique!== BCE_JUR_FORM_NAME_VZW.abreviationNL)?<option>{BCE_JUR_FORM_NAME_VZW.abreviationNL}</option>:null}
                          {/**/}
                          {(valueFormJuridique!== BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.abreviationNL)?<option>{BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.abreviationNL}</option>:null}
                          {(valueFormJuridique!== BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.abreviationNL)?<option>{BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.abreviationNL}</option>:null}
                          {(valueFormJuridique!== BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.abreviationNL)?<option>{BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.abreviationNL}</option>:null}
                          {/**/}
                          {(valueFormJuridique!== BCE_JUR_FORM_NAME_Overige_rechtsvormen.abreviationNL)?<option>{BCE_JUR_FORM_NAME_Overige_rechtsvormen.abreviationNL}</option>:null}
                        </Form.Control>:
                        <Form.Control as="select"  onChange={(e) => this.setState({valueFormJuridique: e.target.value})}>
                          <option  selected value="" disabled="disabled">{placeholderFormJuredique}</option>
                          <option>{BCE_JUR_FORM_NAME_NV.abreviationNL}</option>
                          <option>{BCE_JUR_FORM_NAME_BVBA.abreviationNL}</option>
                          <option>{BCE_JUR_FORM_NAME_CV.abreviationNL}</option>
                          <option>{BCE_JUR_FORM_NAME_Eenmanszaak.abreviationNL}</option>
                          <option>{BCE_JUR_FORM_NAME_VZW.abreviationNL}</option>
                          {/**/}
                          <option>{BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.abreviationNL}</option>
                          <option>{BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.abreviationNL}</option>
                          <option>{BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.abreviationNL}</option>
                          {/**/}
                          <option>{BCE_JUR_FORM_NAME_Overige_rechtsvormen.abreviationNL}</option>
                        </Form.Control>}
                        </div> </Form.Group>
                    </Col>:null}
                    {(companyExist)?
                    <Col lg="3">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder={placeholderCompanyNumber}
                          value={this.state.numero_d_enterprise}
                          onChange={e => this.setState({numero_d_enterprise: e.target.value})}
                        />
                      </Form.Group>
                    </Col>:null}
                    <Col lg="6">
                      <Form.Group>
                        <Select
                          placeholder={placeholderNacebel}
                          options={this.state.options}
                          onChange={(value) => this.onselectChange(value)}
                          value={this.state.selectedValue}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group>
                        {this.renderDateField()}
                      </Form.Group>
                    </Col>
                    <Col lg="9">
                      <Form.Group>
                        <Form.Control type="text" placeholder={placeholderStreet} value={this.state.rue}
                                      onChange={e => this.setState({rue: e.target.value})}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="3">
                      <Form.Group>
                        <Form.Control type="text" placeholder={placeholderNumber} value={this.state.numero_de_maison_ajout}
                                      onChange={e => this.setState({numero_de_maison_ajout: e.target.value})}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group>
                        <Form.Control type="text" placeholder={placeholderPostCode} value={this.state.code_postal}
                                      onChange={e => {this.setState({code_postal: e.target.value}); this.checkCP(e.target.value); }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group>
                        <Form.Control type="text" placeholder={placeholderCity} value={this.state.lieu}
                                      onChange={e => this.setState({lieu: e.target.value})}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-md-flex justify-content-center mt-3 form-group row">
                  {(companyExist)? 
                  <Button variant="success" type="submit" className="button-yellow"  disabled={(!this.state.nom_de_commerce || !this.state.valueFormJuridique  || !this.state.establishmentDateValue   )} >{translate('verder gaan')}</Button>
                  :
                  <Button variant="success" type="submit" className="button-yellow"  disabled={( !this.state.establishmentDateValue )} >{translate('verder gaan')}...</Button>
                  }


                  
                  </div>   
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {(showAlert)?
        <ModalAlert
          alertTitle={alertTitle}
          alertMessage={alertMessage}
          closeModal={() => this.setState({showAlert: false})}
        />:null
      }
    </Layout>
  
  )
  }
}

export default UserCheckInfoTwo
